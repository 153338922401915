import React from 'react';
import StarList from '../StarList/StarList';

import {
  HeadingRow,
  QuoteContainer,
  StarRow,
  QuoteWrapper,
} from './QuoteSection.styles';

const QuoteSection = ({ date, body, starCount, headline }) => {
  return (
    <QuoteContainer>
      <StarRow>
        <StarList starCount={starCount || 5.0} />
      </StarRow>
      <QuoteWrapper>
        <HeadingRow>
          <h3 className="review-headline">
            {headline || 'Shopmonkey is the best!'}
          </h3>
          <div className="review-date">{date}</div>
        </HeadingRow>
        {body && (
          <div className="message" dangerouslySetInnerHTML={{ __html: body }} />
        )}
      </QuoteWrapper>
    </QuoteContainer>
  );
};

export default QuoteSection;
