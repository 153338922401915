import React from 'react';
import { BottomContainer, BottomWrapper, InfoBox } from './CardBottom.styles';

const CardBottom = ({ shopType, geolocation, switchedFrom }) => {
  const formatNotAvailableText = text => {
    if (text === 'Na') {
      return 'N/A';
    }
    return text;
  };

  return (
    <BottomWrapper>
      <BottomContainer>
        <InfoBox className="shop-type-box">
          <div className="info-type">Shop Type</div>
          <div className="info-value"> {formatNotAvailableText(shopType)}</div>
        </InfoBox>
        <InfoBox className="location-box">
          <div className="info-type">Location</div>
          <div className="info-value">
            {formatNotAvailableText(geolocation)}
          </div>
        </InfoBox>
        <InfoBox className="review-source-box">
          <div className="info-type">Switched From</div>
          <div className="info-value">
            {' '}
            {formatNotAvailableText(switchedFrom)}
          </div>
        </InfoBox>
      </BottomContainer>
    </BottomWrapper>
  );
};
export default CardBottom;
