import React from 'react';
import CardBottom from './CardBottom/CardBottom';
import QuoteSection from './QuoteSection/QuoteSection';
import { ReviewContainer, ReviewWrapper } from './ReviewCard.styles';
import AttributionSection from './AttributionSection/AttributionSection';

import { optionNameOverride } from '../../TestimonialListing/utils/optionNameOverride';

const ReviewCard = ({ component }) => {
  const {
    date,
    video,
    headline,
    person,
    body,
    shopType,
    starCount,
    geolocation,
    switchedFrom,
    reviewLocation,
    isExternalReview,
    originalReviewLink,
  } = component;

  const geoString = optionNameOverride(geolocation);
  const shopTypeString = optionNameOverride(shopType);
  const switchedFromStr = optionNameOverride(switchedFrom);

  return (
    <ReviewContainer>
      <QuoteSection
        body={body}
        headline={headline}
        starCount={starCount}
        date={date}
      />
      <ReviewWrapper>
        <AttributionSection
          isExternalReview={isExternalReview}
          reviewLink={originalReviewLink}
          person={person}
          video={video}
        />
        <CardBottom
          shopType={shopTypeString}
          geolocation={geoString}
          switchedFrom={switchedFromStr}
          reviewLocation={reviewLocation}
          isExternalReview={isExternalReview}
        />
      </ReviewWrapper>
    </ReviewContainer>
  );
};

export default ReviewCard;
